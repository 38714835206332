const form = document.getElementById('contact-form');
const tryAgain = document.getElementById('retry');

form.addEventListener('submit', function (event) {
    event.preventDefault();
    form.classList.add('form_pending');

    emailjs.sendForm('default_service', 'website_template', this).then((success) => {
        form.classList.remove('form_pending');
        form.classList.add('form_success');
        console.log(success);

    }, (error) => {
        console.log(error);
        form.classList.remove('form_pending');
        form.classList.add('form_fail');
    });
});

tryAgain.onclick = () => {
    form.classList.remove('form_fail');
};
