const utils = require('./utils');

const _scrollTos = document.querySelectorAll('[scroll-to]');
const _nav = document.querySelector('nav');
const d = 50;

/**
 * Adds click event to all elements with a 'scroll-to' attribute.
 */
_scrollTos.forEach(_scrollTo => {
    _scrollTo.onclick = (e) => {
        utils.fireEvent('stopScrollEvent');
        let offset = e.target.getAttribute('scroll-offset') ? parseInt(e.target.getAttribute('scroll-offset')) : 0;
        let to = document.getElementById(`${e.target.getAttribute('scroll-to')}`);
        smoothScroll(to, d, offset);
    };
});

/**
 * Function to scroll the page to an element
 * @param el Element to scroll to
 * @param duration Duration of the
 * @param offset To adjust the scroll position
 */
const smoothScroll = (el, duration, offset = 0) => {
    let to = window.pageYOffset + el.getBoundingClientRect().top - _nav.offsetHeight + offset;
    scrollFrame(to, duration);
};

const scrollFrame = (to, duration) => {
    if (duration < 0) {
        if (window.location.href) {
            history.pushState("", document.title, window.location.pathname);
        }

        utils.fireEvent('stopScrollEvent');
        utils.fireEvent('startScrollEvent');
        return;
    }
    let scrollTop = window.scrollY;
    let difference = to - scrollTop;
    setTimeout(() => {
        window.requestAnimationFrame(function () {
            window.scrollTo(0, scrollTop + difference * easeOutSine(d - duration));
            scrollFrame(to, duration - 1);
        });
    }, 1);
};

const easeOutSine = function (t) {
    return 1 / 4 * Math.sin(t / d * (Math.PI / 2));
};

module.exports = smoothScroll;