const lozad = require('lozad');

const observer = lozad('.lozad', {
    rootMargin: '250px 0px',
    loaded: function (el) {
        if (el.localName === 'img') {
            let objImg = new Image();
            objImg.src = el.dataset.src;

            objImg.onload = function () {
                el.classList.add('lozad--loaded');
                setTimeout(function () {
                })
            };
        } else if (el.localName === 'picture') {
            el.classList.add('lozad--loading');
            let watcher = setInterval(function () {
                if (el.querySelector('img').currentSrc) {
                    window.clearInterval(watcher);
                    let objImg = new Image();
                    objImg.src = el.querySelector('img').currentSrc;
                    objImg.onload = function () {
                        el.classList.add('lozad--loaded');
                    };
                }
            }, 100);
        } else if (el.localName === 'div') {
            let objImg = new Image();

            objImg.src = el.dataset.backgroundImage;

            objImg.onload = function () {
                el.classList.add('lozad--loaded');
            };
        } else {
            el.classList.add('lozad--loaded');
        }

    }
});
observer.observe();