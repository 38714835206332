module.exports = {
    fireEvent: function (event, param) {
        let eventAction = new CustomEvent(event, { detail: param });
        window.dispatchEvent(eventAction);
    },
    // Returns a function, that, when invoked, will only be triggered at most once
    // during a given window of time. Normally, the throttled function will run
    // as much as it can, without ever going more than once per `wait` duration;
    // but if you'd like to disable the execution on the leading edge, pass
    // `{leading: false}`. To disable execution on the trailing edge, ditto.
    throttle: function (func, wait, options) {
        let context, args, result;
        let timeout = null;
        let previous = 0;
        if (!options) options = {};
        let later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            let now = Date.now();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    },
    /**
     * Function handles the adding and removing of the active class on a given DOM element
     */
    toggleActive: function (elem) {
        let _elem = elem || this;
        if (_elem.classList.contains('active')) {
            _elem.classList.remove('active');
        } else {
            _elem.classList.add('active');
        }
    },
    lock: function (bool) {
        const body = document.querySelector('body');
        const stickyTop = document.querySelectorAll('.sticky--top');

        // Mimic a frozen frame by setting the content to fixed position
        let supportPageOffset = window.pageXOffset !== undefined;
        let isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        // We get the scroll top off the window object as the scroll top value
        let savedScrollTop = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        if (body.style.top) {
            savedScrollTop = body.style.top.split('px')[0] * -1;
        }
        // only exists on the window level in firefox
        if (bool) {
            body.style.position = 'absolute';
            body.style.overflow = 'hidden';
            body.style.width = '100%';
            body.style.height = '100%';
            body.style.top = -savedScrollTop + 'px';
            stickyTop.forEach((el) => {
                el.style.position = 'fixed';

            });
        } else {
            if (body.style.overflow === 'hidden') {
                // Reset the dom
                stickyTop.forEach((el) => {
                    el.style.position = 'sticky';
                    el.style.position = '-webkit-sticky';
                });
                body.style.position = '';
                body.style.overflow = '';
                body.style.height = '';
                body.style.width = '';
                body.style.top = '';
                window.scrollTo(0, savedScrollTop);
            }
        }
    },
    smallScreenWidth: 544,
    mediumScreenWidth: 812,
    largeScreenWidth: 992,
    xLargeScreenWidth: 1200,
};