function initMap() {
    let location = { lat: -33.889442, lng: 151.083862 };
    let map = new google.maps.Map(document.getElementById('map'), {
        zoom: 14,
        center: location
    });
    let marker = new google.maps.Marker({
        position: location,
        map: map
    });
}

let watcher = setInterval(function () {
    if (typeof google !== 'undefined') {
        window.clearInterval(watcher);
        initMap();
    }
}, 100);