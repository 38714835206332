const utils = require('./utils');
const smoothScroll = require('./smoothScroll');

const _menuBtn = document.querySelector('[clickToExpand]');
const _menu = document.querySelector('.nav-expanded');
const _contactBtns = document.querySelectorAll('[href="/#contact"]');
const _projectBtns = document.querySelectorAll('[href="/#projects"]');
const _contactContainer = document.querySelector('[contact-container]');
const _projectContainer = document.querySelector('[project-container]');
const d = 50;

_menuBtn.onclick = () => {
    if (_menuBtn.innerHTML !== 'Close') {
        _menu.style.visibility = 'visible';
        _menu.style.height = 'calc(100vh - 116px)';
        _menuBtn.innerHTML = 'Close';
        utils.lock(true);
    } else {
        _menu.style.visibility = 'hidden';
        _menu.style.height = '0';
        _menuBtn.innerHTML = 'Menu';
        utils.lock(false);
    }
};

window.onload = function () {
    if (window.location.hash === '#contact') {
        smoothScroll(_contactContainer, d);
    }
    if (window.location.hash === '#projects') {
        smoothScroll(_projectContainer, d);
    }
};

_contactBtns.forEach((button) => {
    button.onclick = function () {
        let offset = button.getAttribute('scroll-offset') ? parseInt(button.getAttribute('scroll-offset')) : 0;

        if (window.innerWidth <= utils.mediumScreenWidth && _menuBtn.innerHTML === 'Close') {
            _menuBtn.click();
        }
        setTimeout(function () {
            if (window.location.hash === '#contact') {
                smoothScroll(_contactContainer, d, offset);
            }
        }, 300);
    };
});

_projectBtns.forEach((button) => {
    button.onclick = function () {
        let offset = button.getAttribute('scroll-offset') ? parseInt(button.getAttribute('scroll-offset')) : 0;

        if (window.innerWidth <= utils.mediumScreenWidth && _menuBtn.innerHTML === 'Close') {
            _menuBtn.click();
        }
        setTimeout(function () {
            if (window.location.hash === '#projects') {
                smoothScroll(_projectContainer, d, offset);
            }
        }, 300);
    };
});